import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import image from '../assets/svg/image.svg';

import SeoForm from '../components/forms/seoForm';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class SeoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }



    render() {

        return (
            <div className="page-wrap">
                            {
                    !localStorage.auth ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>SEO tags</h3>
                        </Col>

                    </Row>
                    
                    <SeoForm />
                </Container>


            </div>
        );
    }
}

export default Page(SeoPage);
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import HtmlImage from './fields/htmlImage';
import Html from './fields/html';

import image from '../../assets/svg/image.svg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )



const renderHtmlField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Html
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Title</h3>
                                <h6 className="subtitle">Enter title of review</h6>

                            </Col>
                            <Col lg="6" className="input-wrap">
                                <Field
                                    name="name"
                                    component={renderTextField}
                                    label={"Title"}
                                    placeholder="Enter title of review"

                                ></Field>

                            </Col>
                            <Col lg="12">
                                <h3 className="title">Content</h3>
                                <h6 className="subtitle">Enter review content</h6>

                            </Col>
                            <Field
                                name="content"
                                component={renderHtmlField}
                                placeholder="Unesite tekst"

                            ></Field>


                        </Row>
                    </Container>
                </Col>

                <Col lg="12">
                    <button className="button">Dodaj stranicu</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import visitIcon from '../assets/svg/visit.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class ContactListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        if (!localStorage.auth){
            return;
        }

        fetch('https://api.aimedia.ch/admin/contacts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })



    }


    render() {

        return (
            <div className="page-wrap">
                            {
                    !localStorage.auth ? <Redirect to='/login' /> : null
                }

                <Container fluid className="table">

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>All contacts</h3>
                        </Col>
                    </Row>
                    <Row className="table-head">
                        <Col lg="1">
                            <div className="checkbox-wrap">
                                <div className="checkbox"></div>
                            </div>
                        </Col>
                        <Col lg="2">
                            
                            <span className="name">NAME</span>
                        </Col>
                        <Col lg="4">
                            
                            <span className="name">MESSAGE</span>
                        </Col>
                        <Col lg={{ size: 2, offset: 1 }}>
                            
                            <span className="name">E-MAIL</span>
                        </Col>
                        <Col lg="2" className="actions">

                            <span className="name">OPTIONS</span>
                        </Col>

                    </Row>
                    {
                        this.state.items.map((item, idx) => {
                            return (
                                <Row className="table-row">
                                    <Col lg="1">
                                        <div className="checkbox-wrap">
                                            <div className="checkbox"></div>
                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <span className="value">{item.name}</span>
                                    </Col>
                                    <Col lg="4">
                                        <span className="value">{item.message && item.message.length > 68 ? item.message.substring(0, 68) + '...' : item.message}</span>
                                    </Col>
                                    <Col lg={{ size: 2, offset: 1 }}>
                                        <span className="value">{item.email}</span>
                                    </Col>
                                    <Col lg="2" className="actions">
                                        <Link to={`/contacts/${item.id}`}><Isvg src={visitIcon} /></Link>

                                    </Col>

                                </Row>

                            )
                        })
                    }

                </Container>



            </div>
        );
    }
}

export default Page(ContactListPage);
import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo1.png';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';


class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <img src={logo} />
                        <h2>React<span>Admin</span></h2>
                    </div>
                    <div className="menu" onClick={() => this.setState({_show: !this.state._show})}>
                        <Isvg src={menu} />
                    </div>
                </div>

                <div className="items">
                    <h6>CONTENT</h6>
                    <ul>
                        <li>
                            <Link to='/pages' className={this.props[0].location.pathname == '/pages' ? 'active' : null}>
                                <Isvg src={list} />
                                All pages
                            </Link>
                        </li>
                        <li>
                            <Link to='/pages/new' className={this.props[0].location.pathname == '/pages/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add page
                            </Link>
                        </li>

                    </ul>
                    <h6>TEMOIGNAGES</h6>
                    <ul>
                        <li>
                            <Link to='/reviews' className={this.props[0].location.pathname == '/reviews' ? 'active' : null}>
                                <Isvg src={list} />
                                All reviews
                            </Link>
                        </li>
                        <li>
                            <Link to='/reviews/new' className={this.props[0].location.pathname == '/reviews/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add review
                            </Link>
                        </li>

                    </ul>

                    <h6>CONTACT</h6>
                    <ul>
                        <li>
                            <Link to='/contacts' className={this.props[0].location.pathname == '/contacts' ? 'active' : null}>
                                <Isvg src={mail} />
                                All contacts
                            </Link>
                        </li>

                    </ul>


                    <h6>SETTINGS</h6>
                    <ul>
                        <li>
                            <Link to='/config' className={this.props[0].location.pathname == '/config' ? 'active' : null}>
                                <Isvg src={settings} />
                                Site data
                            </Link>
                        </li>
                        <li>
                            <Link to='/seo' className={this.props[0].location.pathname == '/seo' ? 'active' : null}>
                                <Isvg src={rocket} />
                                SEO tags
                            </Link>
                        </li>
                    </ul>

                      <ul className="logout">
                        <li onClick={() => localStorage.removeItem('auth')}>
                            <Link to='/login' className={this.props[0].location.pathname == '/seo' ? 'active' : null}>
                                <Isvg src={exit} />
                                Logout
                            </Link>
                        </li>
                    </ul> 

                </div>


            </div>
        )
    }

};

export default Sidebar;